import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article09032021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">GOOD START OF FINANCIAL YEAR 2021</h1>
            <p className="bullet">
              Strong order intake in the first weeks of financial year 2021
            </p>
            <p className="bullet">
              TTS (Trust-Transparency-Share) program launched
            </p>
            <p className="bullet">Maximum price for share buybacks increased</p>

            <p>
              After the order backlog already increased by 10% in 2020 despite
              the Corona crisis, the increase of the order intake of S&T AG and
              the S&T Group (ISIN: AT0000A0E9W5, WKN: A0X9EJ, stock exchange
              symbol: SANT) has again accelerated significantly in recent
              months. The largest order we received at the beginning of 2021 was
              from a medical technology company with a volume of more than EUR
              62 million. Furthermore, S&T was able to win public tenders from
              state or state-related rail infrastructure operators in the amount
              of more than EUR 81 million in past months. Particularly to
              mention here are SDCZ (Czech Republic) with an order volume of EUR
              25 million and LTG (Lithuania) with EUR 16 million. Further train
              projects were won in Germany, France, the United Kingdom and
              Spain. The public sector also developed very positively with EUR
              82 million in new orders in the first quarter of 2021, of which
              the most, with EUR 44 million, came from Poland. In total, we
              expect new orders to exceed revenues by around 20% in the 1st
              quarter of the current financial year. This will lead to an
              increase in organic growth in the financial year 2021, although
              there may be slight shifts in sales from Q1 2021 to subsequent
              quarters due to the current shortage of microchips.
            </p>
            <p>
              Likewise, the implementation of the TTS program
              (Trust-Transparency-Share) was started in the first quarter of
              2021. The TTS program aims is to strengthen confidence in the S&T
              AG share through increased transparency. In addition to the
              publication of further key figures (for example M&A effects,
              revenue distribution, etc.), there will also be ongoing reporting
              on improvements, for example the reduction of subsidiaries or in
              course of the PEC² program. Concerning our shareholders, the new
              profit distribution provides for 50% of the annual net profit to
              be used in the form of share buybacks or dividends, while 50% will
              be invested in further growth under Agenda 2023. Therefore, the
              maximum price for share buybacks under the current Share buyback
              program II 2020 was also increased from EUR 20.00 to EUR 22.50 as
              of March 8, 2021. This was done in line with S&T's strategy to
              acquire treasury shares at favorable conditions - not more
              expensive than 10 times EBITDA. For the 2020 financial year,
              EBITDA guidance was between EUR 115 and 122 million, resulting in
              our maximum price of EUR 20.00 for share buybacks. Based on the
              increased share price and the EBITDA guidance of at least EUR 140
              million for 2021, the maximum price was increased to EUR 22.50.
            </p>
            <p>
              Hannes Niederhauser, CEO of S&T AG: "The new financial year got
              off to a very encouraging start but there is a lot to do. 2020 was
              burdened by the Corona pandemic and at the beginning of the
              current financial year our business is affected by the chip
              shortage. Nevertheless, I am very optimistic that we will be able
              to at least meet or exceed our guidance in 2021, as in previous
              years, due to good customer demand."
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article09032021;
